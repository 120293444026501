import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state() {
        return {
            routeList: [],
            currentRoute: '',
            count: 10
        }
    },
    getters: {
        routeInfo: (state) => {
            let rst = {}
            let index = state.routeList.indexOf(state.currentRoute)
            if(index == -1) {
                rst.hasNext = false
                rst.hasPrev = false
                return rst
            }
            if(index == (state.routeList.length - 1)) {
                rst.hasNext = false
            } else {
                rst.hasNext = true,
                rst.nextPath = state.routeList[index + 1]
            }
            if(index == 0) {
                rst.hasPrev = false
            } else {
                rst.hasPrev = true
                rst.prevPath = state.routeList[index - 1]
            }
            return rst
        }
    },
    mutations: {
        SET_CURRENT_ROUTE(state, path) {
            state.currentRoute = path
        }
    }
})

export default store

