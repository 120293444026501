import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'

// 分页组件
import Pagination from "@/components/Pagination";
// 富文本组件
import Editor from "@/components/Editor"

Vue.config.productionTip = false

Vue.use(Element)

Vue.component('Pagination', Pagination)
Vue.component('Editor', Editor)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
