
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
 
const routeArr = [
	{
		path: "/",
		redirect: {path: '/index'}
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('@/views/Index.vue'),
		meta: {title: '首页'}
	},
	{
		path: '/statistics',
		name: 'statistics',
		component: () => import('@/views/Statistics.vue'),
		meta: {title: '涨跌统计'}
	}
]

export default new Router({
	mode: 'history', // 去掉url中的#
	scrollBehavior: () => ({ y: 0 }),
	routes: routeArr
  })